import React, { FC } from 'react';
import styled from 'styled-components';

type DocumentsFieldProps = {
  documents: string[];
};

const DocumentsField: FC<DocumentsFieldProps> = ({ documents }) => {
  const pdfRegexp = /(?<=identity-documents\/).*(?=\.pdf)/g;
  return (
    <Root>
      {documents.map((document, index) => {
        const isPdfFile = document.includes('.pdf');
        return (
          <a key={index} href={document} target="_blank" rel="noreferrer">
            {isPdfFile ? `${document.match(pdfRegexp)}.pdf` : <Image alt="document" src={document} />}
          </a>
        );
      })}
    </Root>
  );
};

export default DocumentsField;

const Root = styled.div`
  display: flex;
  column-gap: 20px;
  align-items: flex-start;
`;

const Image = styled.img`
  max-width: 200px;
  max-height: 200px;
`;
