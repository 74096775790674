import Button from '@mui/material/Button';
import { AttachmentField } from 'components';
import React from 'react';
import { BooleanField, DateField, Show, SimpleShowLayout, TextField, useGetOne, useRedirect } from 'react-admin';
import { useParams } from 'react-router-dom';
import { ResourceName } from 'types';

export enum SystemMessageType {
  BookingConcluded = 'BOOKING_CONCLUDED',
  BookingCreated = 'BOOKING_CREATED',
  OfferRejected = 'OFFER_REJECTED',
  OfferRejectedBySystem = 'OFFER_REJECTED_BY_SYSTEM',
  OfferSending = 'OFFER_SENDING',
  OfferTerminated = 'OFFER_TERMINATED',
}

export enum MessageType {
  Media = 'MEDIA',
  System = 'SYSTEM',
  Text = 'TEXT',
}

const MessagesShow = () => {
  const { id } = useParams();
  const { data } = useGetOne(ResourceName.MESSAGES, { id });
  const redirect = useRedirect();

  const redirectToChat = async () => {
    redirect(`/${ResourceName.CHATS}/${data.chatId}/show`);
  };

  const SystemMessageMapping = {
    [SystemMessageType.BookingConcluded]: (
      <SimpleShowLayout>
        <TextField label="ID чата" source="chatId" />
        <TextField label="Автор" source="senderId" />
        <TextField label="Тип" source="type" />
        <TextField label="Тип системного сообщения" source="systemMessageType" />
        <TextField label="Статус" source="contractData.status" />
        <DateField locales="rus" showTime label="Сообщение создано" source="createdAt" />
        <Button onClick={redirectToChat} variant="contained">
          Вернуться в чат
        </Button>
      </SimpleShowLayout>
    ),
    [SystemMessageType.BookingCreated]: (
      <SimpleShowLayout>
        <TextField label="ID чата" source="chatId" />
        <TextField label="Автор" source="senderId" />
        <TextField label="Тип" source="type" />
        <TextField label="Тип системного сообщения" source="systemMessageType" />
        <BooleanField label="Вечеринки" source="contractData.rules.allowedToHangingOut" />
        <BooleanField label="Курение" source="contractData.rules.allowedToSmoke" />
        <BooleanField label="С детьми" source="contractData.rules.allowedWithChildren" />
        <BooleanField label="С животными" source="contractData.rules.allowedWithPets" />
        <DateField locales="rus" showTime label="Дата прибытия" source="contractData.arrivalDate" />
        <DateField locales="rus" showTime label="Дата выезда" source="contractData.departureDate" />
        <TextField label="Статус" source="contractData.status" />
        <DateField locales="rus" showTime label="Сообщение создано" source="createdAt" />
        <Button onClick={redirectToChat} variant="contained">
          Вернуться в чат
        </Button>
      </SimpleShowLayout>
    ),
    [SystemMessageType.OfferRejected]: (
      <SimpleShowLayout>
        <TextField label="ID чата" source="chatId" />
        <TextField label="Автор" source="senderId" />
        <TextField label="Тип" source="type" />
        <TextField label="Тип системного сообщения" source="systemMessageType" />
        <TextField label="Статус" source="contractData.status" />
        <DateField locales="rus" showTime label="Сообщение создано" source="createdAt" />
        <Button onClick={redirectToChat} variant="contained">
          Вернуться в чат
        </Button>
      </SimpleShowLayout>
    ),
    [SystemMessageType.OfferRejectedBySystem]: (
      <SimpleShowLayout>
        <TextField label="ID чата" source="chatId" />
        <TextField label="Автор" source="senderId" />
        <TextField label="Тип" source="type" />
        <TextField label="Тип системного сообщения" source="systemMessageType" />
        <TextField label="Статус" source="contractData.status" />
        <DateField locales="rus" showTime label="Сообщение создано" source="createdAt" />
        <Button onClick={redirectToChat} variant="contained">
          Вернуться в чат
        </Button>
      </SimpleShowLayout>
    ),
    [SystemMessageType.OfferSending]: (
      <SimpleShowLayout>
        <TextField label="ID чата" source="chatId" />
        <TextField label="Автор" source="senderId" />
        <TextField label="Тип" source="type" />
        <TextField label="Тип системного сообщения" source="systemMessageType" />
        <TextField label="Период аренды" source="contractData.apartmentRentPeriodType" />
        <BooleanField label="Вечеринки" source="contractData.rules.allowedToHangingOut" />
        <BooleanField label="Курение" source="contractData.rules.allowedToSmoke" />
        <BooleanField label="С детьми" source="contractData.rules.allowedWithChildren" />
        <BooleanField label="С животными" source="contractData.rules.allowedWithPets" />
        <DateField locales="rus" showTime label="Дата прибытия" source="contractData.arrivalDate" />
        <DateField locales="rus" showTime label="Дата выезда" source="contractData.departureDate" />
        <TextField label="Стоимость" source="contractData.cost" />
        <TextField label="Правила отмены" source="contractData.shortTermRentCancellationPolicyType" />
        <TextField label="Статус" source="contractData.status" />
        <DateField locales="rus" showTime label="Сообщение создано" source="createdAt" />
        <Button onClick={redirectToChat} variant="contained">
          Вернуться в чат
        </Button>
      </SimpleShowLayout>
    ),
    [SystemMessageType.OfferTerminated]: (
      <SimpleShowLayout>
        <TextField label="ID чата" source="chatId" />
        <TextField label="Автор" source="senderId" />
        <TextField label="Тип" source="type" />
        <TextField label="Тип системного сообщения" source="systemMessageType" />
        <TextField label="Период аренды" source="contractData.apartmentRentPeriodType" />
        <BooleanField label="Вечеринки" source="contractData.rules.allowedToHangingOut" />
        <BooleanField label="Курение" source="contractData.rules.allowedToSmoke" />
        <BooleanField label="С детьми" source="contractData.rules.allowedWithChildren" />
        <BooleanField label="С животными" source="contractData.rules.allowedWithPets" />
        <DateField locales="rus" showTime label="Дата прибытия" source="contractData.arrivalDate" />
        <DateField locales="rus" showTime label="Дата выезда" source="contractData.departureDate" />
        <TextField label="Стоимость" source="contractData.cost" />
        <TextField label="Правила отмены" source="contractData.shortTermRentCancellationPolicyType" />
        <TextField label="Статус" source="contractData.status" />
        <DateField locales="rus" showTime label="Сообщение создано" source="createdAt" />
        <Button onClick={redirectToChat} variant="contained">
          Вернуться в чат
        </Button>
      </SimpleShowLayout>
    ),
  };

  const renderSystemMessage = (systemMessageType: SystemMessageType) => {
    return SystemMessageMapping[systemMessageType];
  };

  const renderTextMessage = () => {
    return (
      <SimpleShowLayout>
        <TextField label="ID чата" source="chatId" />
        <TextField label="Автор" source="senderId" />
        <TextField label="Тип" source="type" />
        <TextField label="Сообщение" source="text" />
        <DateField locales="rus" showTime label="Сообщение создано" source="createdAt" />
        <Button onClick={redirectToChat} variant="contained">
          Вернуться в чат
        </Button>
      </SimpleShowLayout>
    );
  };

  const renderMediaMessage = () => {
    return (
      <SimpleShowLayout>
        <TextField label="ID чата" source="chatId" />
        <TextField label="Автор" source="senderId" />
        <TextField label="Тип" source="type" />
        <AttachmentField fileKey={data.fileKey} fileName={data.fileName} />
        <DateField locales="rus" showTime label="Сообщение создано" source="createdAt" />
        <Button onClick={redirectToChat} variant="contained">
          Вернуться в чат
        </Button>
      </SimpleShowLayout>
    );
  };

  const MessageMapping = {
    [MessageType.System]: renderSystemMessage(data.systemMessageType),
    [MessageType.Text]: renderTextMessage(),
    [MessageType.Media]: renderMediaMessage(),
  };

  const renderMessage = (messageType: MessageType) => {
    return MessageMapping[messageType];
  };

  return (
    <Show>
      <SimpleShowLayout>{renderMessage(data.type)}</SimpleShowLayout>
    </Show>
  );
};

export default MessagesShow;
