import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from '@material-ui/core';
import { DocumentsField, StyledImageField } from 'components';
import { confirmOptions } from 'const/confirm';
import { FC } from 'react';
import {
  ArrayField,
  Datagrid,
  DateField,
  EmailField,
  SaveButton,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  Toolbar,
  useGetOne,
  useRedirect,
} from 'react-admin';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { confirmSchema } from 'schemaValidators/confirmSchema';
import { http } from 'services';
import { ResourceName } from 'types';
import { ConfirmTypes } from 'types/confirm';

interface MyToolbarProps {
  isDisabled: boolean;
}

const MyToolbar: FC<MyToolbarProps> = ({ isDisabled }) => (
  <Toolbar>
    <SaveButton disabled={isDisabled} />
  </Toolbar>
);

const UserIdentityRequestsShow = () => {
  const { id } = useParams();
  const { data } = useGetOne(ResourceName.USER_IDENTITY_REQUESTS, { id });
  const redirect = useRedirect();

  const handleSubmit = async (data: FieldValues) => {
    if (data.approveStatus === ConfirmTypes.APPROVED) {
      await http.patch(`${ResourceName.USER_IDENTITY_REQUESTS}/${id}/approve`);
      redirect(`/${ResourceName.USER_IDENTITY_REQUESTS}`);
    }
    if (data.approveStatus === ConfirmTypes.REJECTED) {
      await http.patch(`${ResourceName.USER_IDENTITY_REQUESTS}/${id}/reject`, {
        rejectReason: data.declineReason,
      });
      redirect(`/${ResourceName.USER_IDENTITY_REQUESTS}`);
    }
  };

  const {
    control,
    formState: { isValid, errors },
  } = useForm({
    resolver: yupResolver(confirmSchema),
    defaultValues: {
      declineReason: undefined,
      approveStatus: undefined,
    },
    mode: 'onBlur',
  });

  console.log('errors', errors);

  return (
    <Show>
      <TabbedShowLayout>
        <Tab label="О пользователе">
          <SimpleShowLayout>
            <StyledImageField $size={200} label="Аватар" source="avatarKey" />
            <TextField label="ID" source="id" />
            <TextField label="Телефон" source="phone" />
            <EmailField label="Почта" source="email" />
            <TextField label="Имя" source="firstName" />
            <TextField label="Фамилия" source="lastName" />
            <TextField label="Дата рождения" source="birthDate" />
            <TextField label="Пол" source="gender" />
            <ArrayField label="Поручитель" source="guarantors">
              <Datagrid isRowSelectable={() => false} bulkActionButtons={false}>
                <TextField label="Имя" source="firstName" />
                <TextField label="Фамилия" source="lastName" />
                <TextField label="Телефон" source="phone" />
              </Datagrid>
            </ArrayField>
            <DateField locales="rus" showTime label="Создан" source="createdAt" />
            <DateField locales="rus" showTime label="Обновлен" source="updatedAt" />
            <ArrayField label="Документы" source="identityDocuments">
              <DocumentsField documents={data?.identityDocuments} />
            </ArrayField>
          </SimpleShowLayout>
        </Tab>
        <Tab label="Запрос">
          <SimpleForm onSubmit={handleSubmit} toolbar={<MyToolbar isDisabled={!isValid} />}>
            <Controller
              name="approveStatus"
              control={control}
              render={({ field }) => (
                <>
                  {errors?.approveStatus && (
                    <Typography component="span" variant="caption" color="error">
                      {errors?.approveStatus.message}
                    </Typography>
                  )}
                  <SelectInput
                    validate={errors.approveStatus?.message as any}
                    error
                    label="Статус"
                    source="approveStatus"
                    choices={confirmOptions}
                    {...field}
                  />
                </>
              )}
            />
            <Controller
              name="declineReason"
              control={control}
              render={({ field }) => (
                <>
                  {errors?.declineReason && (
                    <Typography component="span" variant="caption" color="error">
                      {errors.declineReason.message}
                    </Typography>
                  )}
                  <TextInput
                    label="Причина отказа"
                    source="declineReason"
                    multiline
                    sx={{ width: '600px' }}
                    {...field}
                  />
                </>
              )}
            />
          </SimpleForm>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default UserIdentityRequestsShow;
