import { Create, SaveButton, SimpleForm, TextInput, Toolbar, useRedirect } from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { http } from 'services';
import { ResourceName } from 'types';

const MyToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);
const ApartmentFacilitiesCreate = () => {
  const redirect = useRedirect();

  const handleSubmit = async (data: FieldValues) => {
    await http.post(ResourceName.APARTMENT_FACILITIES, {
      title: data.title,
    });
    redirect('/' + ResourceName.APARTMENT_FACILITIES);
  };
  return (
    <Create>
      <SimpleForm onSubmit={handleSubmit} toolbar={<MyToolbar />}>
        <TextInput label="Название" source="title" defaultValue="" multiline sx={{ width: '600px' }} />
      </SimpleForm>
    </Create>
  );
};

export default ApartmentFacilitiesCreate;
