import Typography from '@mui/material/Typography';
import React, { FC } from 'react';
import styled from 'styled-components';

type StyledTextFieldProps = {
  label: string;
  text: string;
};

const StyledTextField: FC<StyledTextFieldProps> = ({ text, ...props }) => {
  return (
    <Root>
      <Typography component="span" variant="body1" {...props}>
        {text}
      </Typography>
    </Root>
  );
};

export default StyledTextField;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 6px;
`;
