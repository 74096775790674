import { ListProps } from '@material-ui/core';
import { FC } from 'react';
import { BooleanField, Datagrid, DateField, List, TextField, TextInput } from 'react-admin';

const CustomersFilter = [
  <TextInput key="id" label="Поиск по id жалобы" source="id" alwaysOn />,
  <TextInput key="senderUserId" label="Поиск по id отправителя" source="senderUserId" alwaysOn />,
  <TextInput key="recipientUserId" label="Поиск по id арендодателя" source="recipientUserId" alwaysOn />,
];

const UserComplaintList: FC<ListProps> = (props) => {
  return (
    <List filters={CustomersFilter} sort={{ field: 'createdAt', order: 'DESC' }} {...props}>
      <Datagrid rowClick="show" isRowSelectable={() => false} bulkActionButtons={false}>
        <TextField label="ID жалобы" source="id" sortable={false} />
        <TextField label="ID отправителя" source="senderUserId" sortable={false} />
        <TextField label="ID арендодателя" source="recipientUserId" sortable={false} />
        <BooleanField label="Рассмотрена" source="isViewed" />
        <DateField locales="rus" showTime label="Создан" source="createdAt" />
        <DateField locales="rus" showTime label="Обновлен" source="updatedAt" />
      </Datagrid>
    </List>
  );
};

export default UserComplaintList;
