import { ListProps } from '@material-ui/core';
import { StyledImageField } from 'components';
import { FC } from 'react';
import { Datagrid, DateField, EmailField, List, TextField, TextInput } from 'react-admin';

const CustomersFilter = [
  <TextInput key="id" label="Поиск по id" source="id||$eq" alwaysOn />,
  <TextInput key="firstName" label="Поиск по имени" source="firstName" alwaysOn />,
  <TextInput key="lastName" label="Поиск по фамилии" source="lastName" alwaysOn />,
];

const UsersList: FC<ListProps> = (props) => {
  return (
    <List filters={CustomersFilter} sort={{ field: 'createdAt', order: 'DESC' }} {...props}>
      <Datagrid rowClick="show" isRowSelectable={() => false} bulkActionButtons={false}>
        <StyledImageField label="Аватар" source="avatarKey" sortable={false} />
        <TextField label="ID" source="id" sortable={false} />
        <TextField label="Телефон" source="phone" sortable={false} />
        <EmailField label="Почта" source="email" sortable={false} />
        <TextField label="Имя" source="firstName" />
        <TextField label="Фамилия" source="lastName" />
        <TextField label="Дата рождения" source="birthDate" />
        <TextField label="Пол" source="gender" />
        <TextField label="Статус" source="identityStatus" />
        <DateField locales="rus" showTime label="Создан" source="createdAt" />
        <DateField locales="rus" showTime label="Обновлен" source="updatedAt" />
      </Datagrid>
    </List>
  );
};

export default UsersList;
