import Typography from '@mui/material/Typography';
import { DateInput, Edit, SaveButton, SelectInput, SimpleForm, TextInput, Toolbar, useRedirect } from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { http } from 'services';
import { ResourceName } from 'types';

enum GenderType {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

type GenderChoicesProps = {
  id: GenderType;
  name: string;
};

type ChangeUserInfoProps = {
  firstName: string;
  middleName: string;
  lastName: string;
  birthDate: string;
  gender: GenderType;
  guarantorFirstName: string;
  guarantorLastName: string;
  guarantorPhone: string;
};

const MyToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

const UsersEdit = () => {
  const { id } = useParams();
  const redirect = useRedirect();

  const handleSubmit = async (data: FieldValues) => {
    await changeUserInfo({
      firstName: data.firstName,
      middleName: data.middleName,
      lastName: data.lastName,
      birthDate: data.birthDate,
      gender: data.gender,
      guarantorFirstName: data.guarantors[0].firstName,
      guarantorLastName: data.guarantors[0].lastName,
      guarantorPhone: data.guarantors[0].phone,
    });

    redirect(`/${ResourceName.USERS}`);
  };

  const changeUserInfo = async ({
    firstName,
    middleName,
    lastName,
    birthDate,
    gender,
    guarantorFirstName,
    guarantorLastName,
    guarantorPhone,
  }: ChangeUserInfoProps) => {
    await http.patch(`${ResourceName.USERS}/${id}`, {
      birthdate: birthDate,
      gender: gender,
      userName: {
        firstName: firstName,
        lastName: lastName,
        middleName: middleName,
      },
      guarantor: {
        firstName: guarantorFirstName,
        lastName: guarantorLastName,
        phone: guarantorPhone,
      },
    });
  };

  const genderChoices: GenderChoicesProps[] = [
    {
      id: GenderType.MALE,
      name: 'Мужской',
    },
    {
      id: GenderType.FEMALE,
      name: 'Женский',
    },
  ];

  return (
    <Edit>
      <SimpleForm onSubmit={handleSubmit} toolbar={<MyToolbar />}>
        <TextInput label="Имя" source="firstName" defaultValue="" sx={{ width: '450px' }} />
        <TextInput label="Отчество" source="middleName" defaultValue="" sx={{ width: '450px' }} />
        <TextInput label="Фамилия" source="lastName" defaultValue="" sx={{ width: '450px' }} />
        <DateInput label="Дата рождения" source="birthDate" defaultValue="" sx={{ width: '450px' }} />
        <SelectInput label="Пол" source="gender" choices={genderChoices} />
        <Typography component="p" variant="body1">
          Контактное лицо при ЧП
        </Typography>
        <TextInput label="Имя" source="guarantors[0].firstName" defaultValue="" sx={{ width: '450px' }} />
        <TextInput label="Фамилия" source="guarantors[0].lastName" defaultValue="" sx={{ width: '450px' }} />
        <TextInput label="Номер телефона" source="guarantors[0].phone" defaultValue="" sx={{ width: '450px' }} />
      </SimpleForm>
    </Edit>
  );
};

export default UsersEdit;
