import { ConfirmTypes } from 'types/confirm';
import * as yup from 'yup';

export const confirmSchema = yup
  .object({
    declineReason: yup
      .string()
      .required()
      .when('approveStatus', (approveStatus) => {
        if (approveStatus === ConfirmTypes.REJECTED) {
          return yup.string().required('Причина отказа должна быть указана');
        }

        return yup.string().nullable();
      }),
    approveStatus: yup.string().required('Статус должен быть указан'),
  })
  .required();
