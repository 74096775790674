import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { FC, useState } from 'react';

import { cancelRentAsync } from '../../api';
import { RolesEnum } from '../../types';

type CancelRentTenantModalProps = {
  isOpen: boolean;
  id: string;
  onClose: () => void;
  onSubmit?: (data: { isPending: boolean }) => void;
};

const CancelRentLandlordModal: FC<CancelRentTenantModalProps> = ({ isOpen, onClose, id, onSubmit: onSubmitCustom }) => {
  const [isGoodReason, setIsGoodReason] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const toggleGoodReason = () => {
    setIsGoodReason(!isGoodReason);
  };

  const cancelRent = async () => {
    try {
      setIsLoading(true);
      const result = await cancelRentAsync({ id, validExcuse: isGoodReason, requestingUserRole: RolesEnum.LANDLORD });
      onClose();
      setIsLoading(false);

      return result as { data: { isPending: true } };
    } catch (e) {
      console.warn(e, 'error');
    }
  };

  const onSubmit = async () => {
    const result = await cancelRent();
    if (result?.data?.isPending != null) {
      onSubmitCustom?.(result.data);
    }
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style} p="10px">
        <Typography fontWeight="500">Вы действительно хотите отменить аренду ?</Typography>
        <Box style={{ display: 'flex', width: '93%', alignItems: 'center' }}>
          <Checkbox checked={isGoodReason} onClick={toggleGoodReason} />
          <Typography fontWeight="500">Уважительная причина</Typography>
        </Box>
        <Box style={stylesBox}>
          <Button style={{ color: 'rgb(234, 114, 114)' }} onClick={onClose}>
            Закрыть
          </Button>
          <Box style={stylesLoadingBox}>
            {isLoading ? <CircularProgress size={20} /> : <Button onClick={onSubmit}>Отменить</Button>}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default CancelRentLandlordModal;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  display: ' flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '10px !important',
  borderRadius: '5px',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const stylesBox = {
  padding: '10px !important',
  display: ' flex',
  width: '90%',
  marginTop: '10px',
  justifyContent: 'space-between',
  alignItems: 'center',
};

const stylesLoadingBox = {
  width: '86px',
  height: '36px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
