import { ListProps } from '@material-ui/core';
import { FC } from 'react';
import { Datagrid, DateField, List, ReferenceField, TextField, TextInput } from 'react-admin';
import { ResourceName } from 'types';

const CustomersFilter = [<TextInput key="id" label="Поиск по id чата" source="id" alwaysOn />];

const ChatsList: FC<ListProps> = (props) => {
  return (
    <List filters={CustomersFilter} sort={{ field: 'createdAt', order: 'DESC' }} {...props}>
      <Datagrid rowClick="show" isRowSelectable={() => false} bulkActionButtons={false}>
        <ReferenceField
          label="Название объявления"
          reference={ResourceName.CONTRACTS}
          source="contractId"
          link={false}
          sortable={false}>
          <TextField source="baseApartmentAdData.title" />
        </ReferenceField>
        <TextField label="ID чата" source="id" sortable={false} />
        <DateField locales="rus" showTime label="Создано" source="createdAt" />
        <DateField locales="rus" showTime label="Обновлено" source="updatedAt" />
      </Datagrid>
    </List>
  );
};

export default ChatsList;
