enum StorageKeys {
  ACCESS_TOKEN = 'accessToken',
}

class StorageService {
  getAccessToken() {
    return localStorage.getItem(StorageKeys.ACCESS_TOKEN);
  }

  setAccessToken(token: string) {
    localStorage.setItem(StorageKeys.ACCESS_TOKEN, token);
  }

  resetAccessToken() {
    localStorage.removeItem(StorageKeys.ACCESS_TOKEN);
  }
}

export default new StorageService();
