import React, { FC } from 'react';
import styled from 'styled-components';

type AttachmentFieldProps = {
  fileKey: string;
  fileName: string;
};

const AttachmentField: FC<AttachmentFieldProps> = ({ fileKey, fileName }) => {
  const isPdfFile = fileName.includes('.pdf');
  return (
    <Root>
      <a href={fileKey} target="_blank" rel="noreferrer">
        {isPdfFile ? <p>{fileName}</p> : <Image src={fileKey} />}
      </a>
    </Root>
  );
};

export default AttachmentField;

const Root = styled.div`
  display: flex;
  column-gap: 20px;
  align-items: flex-start;
`;

const Image = styled.img`
  max-width: 200px;
  max-height: 200px;
`;
