import { BooleanField, NumberField, SimpleShowLayout, TextField, useRecordContext } from 'react-admin';
import styled from 'styled-components';

const LongTermRentFields = () => {
  const record = useRecordContext();

  const isLongTermRent = !!record?.longTermRent;

  if (!isLongTermRent) {
    return null;
  }

  return (
    <StyledSimpleShowLayout>
      <Title>Долгосрочная аренда</Title>
      <BooleanField label="Одобрено" source="longTermRent.isApproved" />
      <TextField label="Статус" source="longTermRent.status.[0]" />
      <NumberField label="Стоимость" source="longTermRent.cost" />
    </StyledSimpleShowLayout>
  );
};

export default LongTermRentFields;

const StyledSimpleShowLayout = styled(SimpleShowLayout)`
  padding: 0;
`;

const Title = styled.h4`
  margin: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  color: rgba(0, 0, 0, 0.6);
`;
