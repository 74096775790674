import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from '@material-ui/core';
import { DocumentsField, LongTermRentFields, ShortTermRentFields, StyledImageField } from 'components';
import { confirmOptions } from 'const/confirm';
import { FC } from 'react';
import {
  ArrayField,
  BooleanField,
  DateField,
  FunctionField,
  SaveButton,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  Toolbar,
  useGetOne,
  useRedirect,
} from 'react-admin';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { confirmSchema } from 'schemaValidators/confirmSchema';
import { http } from 'services';
import { ResourceName } from 'types';
import { ConfirmTypes } from 'types/confirm';

interface MyToolbarProps {
  isDisabled: boolean;
}

const MyToolbar: FC<MyToolbarProps> = ({ isDisabled }) => (
  <Toolbar>
    <SaveButton disabled={isDisabled} />
  </Toolbar>
);

const ApartmentAdRequestLongRentShow = () => {
  const { id } = useParams();
  const { data } = useGetOne(ResourceName?.APARTMENT_AD_REQUESTS_LONG_RENT, { id });
  const redirect = useRedirect();

  const handleSubmit = async (data: FieldValues) => {
    if (data.approveStatus === ConfirmTypes.APPROVED) {
      await http.patch(`${ResourceName?.APARTMENT_AD_REQUESTS_LONG_RENT}/${id}/approve`);
      redirect(`/${ResourceName?.APARTMENT_AD_REQUESTS_LONG_RENT}`);
    }
    if (data.approveStatus === ConfirmTypes.REJECTED) {
      await http.patch(`${ResourceName?.APARTMENT_AD_REQUESTS_LONG_RENT}/${id}/reject`, {
        declineReason: data.declineReason,
      });
      redirect(`/${ResourceName?.APARTMENT_AD_REQUESTS_LONG_RENT}`);
    }
  };

  const {
    control,
    formState: { isValid, errors },
  } = useForm({
    resolver: yupResolver(confirmSchema),
    defaultValues: {
      declineReason: undefined,
      approveStatus: undefined,
    },
    mode: 'onBlur',
  });

  return (
    <Show>
      <TabbedShowLayout>
        <Tab label="Объявление">
          <SimpleShowLayout>
            <TextField label="ID" source="id" />
            <TextField label="Навание" source="description.name" />
            <TextField label="Описание" source="description.description" />
            <TextField label="Тип жилья" source="apartmentType" />
            <ArrayField label="Изображения" source="media.photos">
              <SingleFieldList>
                <StyledImageField $size={200} $margin={16} source="fileKey" />
              </SingleFieldList>
            </ArrayField>
            <ArrayField label="Документы собственности" source="ownershipDocuments">
              <DocumentsField documents={data?.ownershipDocuments} />
            </ArrayField>
            <TextField label="Страна" source="country" />
            <TextField label="Город" source="city" />
            <TextField label="Улица" source="street" />
            <TextField label="Регион" source="region" />
            <TextField label="Номер дома" source="houseNumber" />
            <TextField label="Количество гостей" source="numberOfGuests" />

            <FunctionField
              label="Количество комнат"
              source="numberOfRooms"
              render={(record: any, source?: string) => {
                const numberOfRooms = source ? record?.[source] : '0';
                return numberOfRooms === 8 ? '8+' : numberOfRooms;
              }}
            />

            <LongTermRentFields />
            <ShortTermRentFields />
            <DateField locales="rus" showTime label="Создан" source="createdAt" />
            <DateField locales="rus" showTime label="Обновлен" source="updatedAt" />
          </SimpleShowLayout>
        </Tab>
        <Tab label="Правила дома">
          <BooleanField label="Удаленные просмотр" source="description.remoteView" />
          <BooleanField label="Самостоятельное прибытие" source="description.selfCheckIn" />
          <BooleanField label="Бесплатная парковка" source="description.freeParking" />
          <BooleanField label="Рабочая зона" source="description.workSpace" />
          <BooleanField label="Тикое" source="description.quite" />
          <BooleanField label="Для семей с детьми" source="description.forFamily" />
          <BooleanField label="С животными" source="rules.allowedWithPets" />
          <BooleanField label="С детьми" source="rules.allowedWithChildren" />
          <BooleanField label="Курение" source="rules.allowedToSmoke" />
          <BooleanField label="Вечеринки и мероприятия" source="rules.allowedToHangingOut" />
        </Tab>
        <Tab label="Запрос">
          <SimpleForm onSubmit={handleSubmit} toolbar={<MyToolbar isDisabled={!isValid} />}>
            <Controller
              name="approveStatus"
              control={control}
              render={({ field }) => (
                <>
                  {errors?.approveStatus && (
                    <Typography component="span" variant="caption" color="error">
                      {errors?.approveStatus.message}
                    </Typography>
                  )}
                  <SelectInput label="Статус" source="approveStatus" choices={confirmOptions} {...field} />
                </>
              )}
            />
            <Controller
              name="declineReason"
              control={control}
              render={({ field }) => (
                <>
                  {errors?.declineReason && (
                    <Typography component="span" variant="caption" color="error">
                      {errors.declineReason.message}
                    </Typography>
                  )}
                  <TextInput
                    label="Причина отказа"
                    source="declineReason"
                    multiline
                    sx={{ width: '600px' }}
                    {...field}
                  />
                </>
              )}
            />
          </SimpleForm>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default ApartmentAdRequestLongRentShow;
