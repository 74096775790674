import Button from '@mui/material/Button';
import { StyledTextField } from 'components';
import React from 'react';
import {
  Datagrid,
  DateField,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  Show,
  ShowButton,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TextField,
  useGetOne,
  useRedirect,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ResourceName } from 'types';

const ChatsShow = () => {
  const { id } = useParams();
  const { data: ChatsData } = useGetOne(ResourceName.CHATS, { id: id });
  const { data: ContractData } = useGetOne(ResourceName.CONTRACTS, { id: ChatsData.contractId });
  const redirect = useRedirect();

  const SystemMessageShowButton = () => {
    return <ShowButton label="Подробнее" />;
  };

  const redirectToUser = async (id: string) => {
    redirect(`/${ResourceName.USERS}/${id}/show`);
  };

  return (
    <Show>
      <TabbedShowLayout>
        <Tab label="Сообщения">
          <SimpleShowLayout>
            <ReferenceManyField
              sort={{ field: 'createdAt', order: 'DESC' }}
              label=""
              pagination={<Pagination />}
              reference={ResourceName.MESSAGES}
              target="chatId">
              <Datagrid isRowSelectable={() => false} bulkActionButtons={false}>
                <StyledText label="Сообщение" source="text" sortable={false} />
                <ReferenceField
                  label="Автор"
                  reference={ResourceName.USERS}
                  source="senderId"
                  link={false}
                  sortable={false}>
                  <TextField source="firstName" /> <TextField source="lastName" />
                </ReferenceField>
                <TextField label="Тип" source="type" sortable={false} />
                <TextField label="Тип системного сообщения" source="systemMessageType" sortable={false} />
                <DateField locales="rus" showTime label="Отправлено" source="createdAt" />
                <SystemMessageShowButton />
              </Datagrid>
            </ReferenceManyField>
          </SimpleShowLayout>
        </Tab>
        <Tab label="Арендатор">
          {ContractData && (
            <SimpleShowLayout>
              <StyledTextField label="ID арендатор" text={ContractData.tenantId} />
              <ReferenceField label="Имя" reference={ResourceName.CONTRACTS} source="contractId" link={false}>
                <ReferenceField reference={ResourceName.USERS} source="tenantId" link={false}>
                  <TextField source="firstName" />
                </ReferenceField>
              </ReferenceField>
              <ReferenceField label="Фамилия" reference={ResourceName.CONTRACTS} source="contractId" link={false}>
                <ReferenceField reference={ResourceName.USERS} source="tenantId" link={false}>
                  <TextField source="lastName" />
                </ReferenceField>
              </ReferenceField>
              <Button onClick={() => redirectToUser(ContractData.tenantId)} variant="contained">
                Профиль арендатора
              </Button>
            </SimpleShowLayout>
          )}
        </Tab>
        <Tab label="Арендодатель">
          {ContractData && (
            <SimpleShowLayout>
              <StyledTextField label="ID арендодатель" text={ContractData.landlordId} />
              <ReferenceField label="Имя" reference={ResourceName.CONTRACTS} source="contractId" link={false}>
                <ReferenceField reference={ResourceName.USERS} source="landlordId" link={false}>
                  <TextField source="firstName" />
                </ReferenceField>
              </ReferenceField>
              <ReferenceField label="Фамилия" reference={ResourceName.CONTRACTS} source="contractId" link={false}>
                <ReferenceField reference={ResourceName.USERS} source="landlordId" link={false}>
                  <TextField source="lastName" />
                </ReferenceField>
              </ReferenceField>
              <Button onClick={() => redirectToUser(ContractData.landlordId)} variant="contained">
                Профиль арендодатель
              </Button>
            </SimpleShowLayout>
          )}
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default ChatsShow;

const StyledText = styled(TextField)`
  display: block;
  max-width: 400px;
  word-break: break-word;
`;
