import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {
  BooleanField,
  DateField,
  Show,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TextField,
  useGetOne,
  useRedirect,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { http } from 'services';
import { ResourceName } from 'types';

enum UserComplaint {
  I_THINK_THEY_ARE_DECEIVING = 'I_THINK_THEY_ARE_DECEIVING',
  THIS_USER_IS_BEHAVING_INDECENTLY = 'THIS_USER_IS_BEHAVING_INDECENTLY',
  THIS_IS_SPAM = 'THIS_IS_SPAM',
  OTHER = 'OTHER',
}

const UserComplaintShow = () => {
  const { id } = useParams();
  const { data } = useGetOne(ResourceName.USER_COMPLAINT, { id });
  const redirect = useRedirect();

  const handleSubmit = async () => {
    await http.patch(`${ResourceName.USER_COMPLAINT}/${id}/view`);
    redirect(`/${ResourceName.USER_COMPLAINT}`);
  };

  const redirectToUser = async (id: string) => {
    redirect(`/${ResourceName.USERS}/${id}/show`);
  };

  const renderComplaint = {
    [UserComplaint.I_THINK_THEY_ARE_DECEIVING]: ' Я думаю меня обманывают',
    [UserComplaint.THIS_USER_IS_BEHAVING_INDECENTLY]: 'Этот пользователь ведет себя непристойно',
    [UserComplaint.THIS_IS_SPAM]: 'Это спам',
    [UserComplaint.OTHER]: 'Другое',
  };

  return (
    <Show>
      <TabbedShowLayout>
        <Tab label="Жалоба">
          <SimpleShowLayout>
            <TextField label="ID жалобы" source="id" />
            <TextField label="ID отправителя" source="senderUserId" />
            <Button onClick={() => redirectToUser(data.senderUserId)} variant="contained">
              Профиль отправителя
            </Button>
            <TextField label="ID арендодателя" source="recipientUserId" />
            <Button onClick={() => redirectToUser(data.recipientUserId)} variant="contained">
              Профиль арендодателя
            </Button>
            <Typography component="p" variant="body1">
              Причины
            </Typography>
            {data.type.map((complaint: UserComplaint) => {
              return (
                <Typography component="span" variant="body1" key={complaint}>
                  {renderComplaint[complaint]}
                </Typography>
              );
            })}
            {data.reason && <TextField label="Комментарий" source="reason" />}
            <BooleanField label="Жалоба рассмотрена" source="isViewed" />
            <DateField locales="rus" showTime label="Создан" source="createdAt" />
            <DateField locales="rus" showTime label="Обновлен" source="updatedAt" />
            <Button onClick={handleSubmit} disabled={data.isViewed} variant="contained">
              Жалоба рассмотрена
            </Button>
          </SimpleShowLayout>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default UserComplaintShow;
