import { useEffect, useState } from 'react';
import { Create, NumberInput, SaveButton, SimpleForm, TextInput, Toolbar, useRedirect } from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { http } from 'services';
import { ResourceName } from 'types';

const MyToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);
interface IForm {
  id: string;
  tag: string;
  order: number;
}
const ShortsHeadingEdit = () => {
  const { id } = useParams();
  const redirect = useRedirect();
  const [form, setForm] = useState<IForm | null>(null);

  const handleSubmit = async (data: FieldValues) => {
    await http.put(ResourceName.SHORTS_HEADING + `/${id}`, {
      tag: data.tag,
      order: data.order,
    });
    redirect('/' + ResourceName.SHORTS_HEADING);
  };

  useEffect(() => {
    http.get(`${ResourceName.SHORTS_HEADING}/${id}`).then((res) => {
      setForm(res.data);
    });
  }, [id]);
  if (!form) {
    return <></>;
  }
  return (
    <Create>
      <SimpleForm onSubmit={handleSubmit} toolbar={<MyToolbar />}>
        <TextInput label="Название" source="tag" defaultValue={form?.tag || ''} sx={{ width: '600px' }} />
        <NumberInput label="Очередь" source="order" defaultValue={form?.order || ''} sx={{ width: '600px' }} />
      </SimpleForm>
    </Create>
  );
};

export default ShortsHeadingEdit;
