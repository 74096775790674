import { BooleanField, NumberField, SimpleShowLayout, TextField, useRecordContext } from 'react-admin';
import styled from 'styled-components';

const ShortTermRentFields = () => {
  const record = useRecordContext();

  const isShortTermRent = !!record?.shortTermRent;

  if (!isShortTermRent) {
    return null;
  }

  return (
    <StyledSimpleShowLayout>
      <Title>Краткосрочная аренда</Title>
      <BooleanField label="Одобрено" source="shortTermRent.isApproved" />
      <TextField label="Статус" source="shortTermRent.status.[0]" />
      <NumberField label="Стоимость" source="shortTermRent.cost" />
      <TextField label="Время прибытия" source="shortTermRent.arrivalTime" />
      <TextField label="Время выезда" source="shortTermRent.departureTime" />
      <TextField label="Тип бронирования" source="shortTermRent.rentBookingType" />
      <NumberField label="Правила отмены" source="shortTermRent.cancellationPolicy" />
    </StyledSimpleShowLayout>
  );
};

export default ShortTermRentFields;

const StyledSimpleShowLayout = styled(SimpleShowLayout)`
  padding: 0;
`;

const Title = styled.h4`
  margin: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  color: rgba(0, 0, 0, 0.6);
`;
