import { Button, ButtonProps, CircularProgress } from '@mui/material';

interface ButtonOptions {
  loading?: boolean;
}

const LoadingButton = <C extends React.ElementType>(props: ButtonProps<C, { component?: C }> & ButtonOptions) => {
  const { loading = false, ...other } = props;

  if (loading) {
    return (
      <Button {...other}>
        {other.children} <CircularProgress style={{ marginLeft: 15 }} size={25} />
      </Button>
    );
  } else {
    return <Button {...other} />;
  }
};

export default LoadingButton;
