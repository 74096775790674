import { TermRentStatus } from 'const';
import { SelectInput, SimpleShowLayout, useRecordContext } from 'react-admin';
import styled from 'styled-components';

const LongTermRentEdit = () => {
  const record = useRecordContext();

  const isLongTermRent = !!record?.longTermRent;
  const isLongTermStatusPaused = record.longTermRent?.status?.[0] === TermRentStatus.PAUSED;
  const isLongTermStatusPublished = record.longTermRent?.status?.[0] === TermRentStatus.PUBLISHED;
  const isLongTermStatus = isLongTermStatusPaused || isLongTermStatusPublished;

  if (!isLongTermRent || !isLongTermStatus) {
    return null;
  }

  return (
    <StyledSimpleShowLayout>
      <Title>Долгосрочная аренда</Title>
      <SelectInput
        label="Статус"
        source="longTermRent.status.[0]"
        required
        choices={[
          { id: TermRentStatus.PUBLISHED, name: TermRentStatus.PUBLISHED },
          { id: TermRentStatus.PAUSED, name: TermRentStatus.PAUSED },
        ]}
      />
    </StyledSimpleShowLayout>
  );
};

export default LongTermRentEdit;

const StyledSimpleShowLayout = styled(SimpleShowLayout)`
  padding: 0;
`;

const Title = styled.h4`
  margin: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  color: rgba(0, 0, 0, 0.6);
`;
