import { LongTermRentFields, ShortTermRentFields, StyledImageField } from 'components';
import { useEffect, useState } from 'react';
import {
  ArrayField,
  BooleanField,
  DateField,
  FunctionField,
  SelectInput,
  Show,
  SimpleForm,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
  useDelete,
  useNotify,
  useRecordContext,
  useRedirect
} from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { http } from 'services';
import { ResourceName } from 'types';

const stylesForButton = {
  color: '#EA7272',
  background: 'transparent',
  height: '30px',
  border: 'none',
  outline: 'none',
  cursor: 'pointer',
  fontWeight: '600',
};

const CustomDeleteButton = () => {
  const record = useRecordContext();
  const notify = useNotify();

  const [deleteOne, { isLoading }] = useDelete(
    'apartment-ad',
    { id: record.id },
    {
      onError: () => {
        notify('Данное объявление нельзя удалить ', { type: 'error' });
      },
      onSuccess: () => {
        notify('Объявление удалено', { type: 'success' });
      },
    },
  );

  const handleClick = () => {
    deleteOne();
  };

  return (
    <button style={{ ...stylesForButton }} disabled={isLoading} onClick={handleClick}>
      Удалить объявление
    </button>
  );
};

const CustomUpdateSlugButton = () => {
  const record = useRecordContext();
  const notify = useNotify();

  const updateSlug = async () => {
    await http.patch(`${ResourceName.APARTMENT_AD}/${record.id}/update-slug`).then((res) => {
      if (res) notify('Объявление обновлено', { type: 'success' });
      else notify('Произошла ошибка', { type: 'error' });
    });
  };

  const handleClick = () => {
    updateSlug();
  };

  return (
    <button style={{ ...stylesForButton, color: '#002D62' }} onClick={handleClick}>
      Обновить Slug
    </button>
  );
};

const CustomAddToShortsButton = ({ haveShorts, shortsData }: any) => {
  const record = useRecordContext();
  const notify = useNotify();
  const redirect = useRedirect();
  const [tags, setTags] = useState<any[]>([]);

  const handleRemoveFromShorts = async () => {
    await http.delete(`${ResourceName.SHORTS}/${shortsData.id}`).then((res) => {
      if (res) notify('Объявление удалено из shorts', { type: 'success' });
      else notify('Произошла ошибка', { type: 'error' });
    });
  };

  const handleAddToShorts = async (tag: any) => {
    await http.post(ResourceName.SHORTS, { apartmentAdId: record.id, tag }).then((res) => {
      if (res) notify('Объявление добавлена в shorts', { type: 'success' });
      else notify('Произошла ошибка', { type: 'error' });
    });
  };

  const handleClick = (data: FieldValues) => {
    if (data.tag === 'Без тэга') {
      handleRemoveFromShorts();
    } else {
      handleAddToShorts(data.tag);
    }
    redirect(`/${ResourceName.APARTMENT_AD}`);
  };

  useEffect(() => {
    http.get(ResourceName.SHORTS_HEADING).then((response) => {
      if (response.data?.data.length > 0) {
        setTags([{ tag: 'Без тэга', id: 'no', }, ...response.data?.data])
      }
    });
  }, []);
  return (
    <SimpleForm onSubmit={handleClick}>
      <SelectInput 
        source="tag"
        optionText="tag"
        optionValue="tag"
        choices={tags}
      />
    </SimpleForm>
  )
};

const ApartmentAdShow = () => {
  const { id } = useParams();
  const [shortsData, setShortsData] = useState(null);
  const [haveShorts, setHaveShorts] = useState(false);
  useEffect(() => {
    http.get(`${ResourceName.SHORTS}?s={"apartmentAdId": "${id}"}`).then(res => {
      setHaveShorts(res?.data.count > 0);
      setShortsData(res?.data.data[0])
    })
  }, [])
  return (
    <Show>
      <TabbedShowLayout>
        <Tab label="Объявление">
          <TextField label="ID" source="id" />
          <TextField label="Навание" source="description.name" />
          <TextField label="Описание" source="description.description" />
          <TextField label="Период аренды" source="rentPeriodType" />
          <TextField label="Тип жилья" source="apartmentType" />
          <ArrayField label="Изображения" source="media.photos">
            <SingleFieldList>
              <StyledImageField $size={200} $margin={16} source="fileKey" />
            </SingleFieldList>
          </ArrayField>
          <TextField label="Страна" source="country" />
          <TextField label="Город" source="city" />
          <TextField label="Улица" source="street" />
          <TextField label="Регион" source="region" />
          <TextField label="Номер дома" source="houseNumber" />
          <TextField label="Количество гостей" source="numberOfGuests" />

          <FunctionField
            label="Количество комнат"
            source="numberOfRooms"
            render={(record: any, source?: string) => {
              const numberOfRooms = source ? record?.[source] : '0';
              return numberOfRooms === 8 ? '8+' : numberOfRooms;
            }}
          />
          <LongTermRentFields />
          <ShortTermRentFields />
          <DateField locales="rus" showTime label="Создан" source="createdAt" />
          <DateField locales="rus" showTime label="Обновлен" source="updatedAt" />
          <CustomDeleteButton />
        </Tab>
        <Tab label="Правила дома">
          <BooleanField label="Удаленные просмотр" source="description.remoteView" />
          <BooleanField label="Самостоятельное прибытие" source="description.selfCheckIn" />
          <BooleanField label="Бесплатная парковка" source="description.freeParking" />
          <BooleanField label="Рабочая зона" source="description.workSpace" />
          <BooleanField label="Тикое" source="description.quite" />
          <BooleanField label="Для семей с детьми" source="description.forFamily" />
          <BooleanField label="С животными" source="rules.allowedWithPets" />
          <BooleanField label="С детьми" source="rules.allowedWithChildren" />
          <BooleanField label="Курение" source="rules.allowedToSmoke" />
          <BooleanField label="Вечеринки и мероприятия" source="rules.allowedToHangingOut" />
        </Tab>
        <Tab label="Обновить данные">
          <CustomAddToShortsButton haveShorts={haveShorts} shortsData={shortsData} />
          <CustomUpdateSlugButton />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default ApartmentAdShow;
