const DeleteButton = ({
  text,
  onClick,
  disabled = false,
}: {
  text: string;
  onClick: () => void;
  disabled?: boolean;
}) => {
  return (
    <button style={{ ...stylesForButton }} disabled={disabled} onClick={onClick}>
      {text}
    </button>
  );
};

const stylesForButton = {
  color: '#EA7272',
  background: 'transparent',
  height: '30px',
  border: 'none',
  outline: 'none',
  cursor: 'pointer',
  fontWeight: '600',
};

export default DeleteButton;
