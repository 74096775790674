import { ListProps } from '@material-ui/core';
import { FC } from 'react';
import { Datagrid, EditButton, List, TextField } from 'react-admin';

const ApartmentFacilitiesList: FC<ListProps> = (props) => {
  return (
    <List {...props}>
      <Datagrid rowClick="show" isRowSelectable={() => false} bulkActionButtons={false}>
        <TextField label="ID удобства" source="id" sortable={false} />
        <TextField label="Название" source="title" sortable={false} />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default ApartmentFacilitiesList;
