import { http } from '../services';

type cancelRentAsyncType = {
  id: string;
  validExcuse?: boolean;
  requestingUserRole: 'TENANT' | 'LANDLORD';
  force?: boolean;
};

export const cancelRentAsync = async ({ id, requestingUserRole, validExcuse, force = false }: cancelRentAsyncType) => {
  try {
    const res = await http.post(`/contracts/${id}/cancel`, {
      cancellationMeta: {
        force,
        validExcuse,
      },
      requestingUserRole,
    });

    return res;
  } catch (e) {
    console.log(e, 'error');
  }
};
