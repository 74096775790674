import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {
  BooleanField,
  DateField,
  Show,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TextField,
  useGetOne,
  useRedirect,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { http } from 'services';
import { ResourceName } from 'types';

enum AprtmentAdComplaint {
  THIS_PLACE_DOES_NOT_EXIST = 'THIS_PLACE_DOES_NOT_EXIST',
  THERE_IS_AN_ERROR_IN_THE_AD = 'THERE_IS_AN_ERROR_IN_THE_AD',
  THIS_IS_A_FRAUD = 'THIS_IS_A_FRAUD',
  OBSCENE_CONTENT = 'OBSCENE_CONTENT',
  OTHER = 'OTHER',
}

const ApartmentAdComplaintShow = () => {
  const { id } = useParams();
  const { data } = useGetOne(ResourceName.APARTMENT_AD_COMPLAINT, { id });
  const redirect = useRedirect();

  const handleSubmit = async () => {
    await http.patch(`${ResourceName.APARTMENT_AD_COMPLAINT}/${id}/viewed`);
    redirect(`/${ResourceName.APARTMENT_AD_COMPLAINT}`);
  };

  const redirectToUser = async (id: string) => {
    redirect(`/${ResourceName.USERS}/${id}/show`);
  };

  const redirectToApartmentAd = async (id: string) => {
    redirect(`/${ResourceName.APARTMENT_AD}/${id}/show`);
  };

  const renderComplaint = {
    [AprtmentAdComplaint.THERE_IS_AN_ERROR_IN_THE_AD]: 'В объявлении есть ошибка',
    [AprtmentAdComplaint.THIS_PLACE_DOES_NOT_EXIST]: 'Такого места не существует',
    [AprtmentAdComplaint.THIS_IS_A_FRAUD]: 'Это мошенничество',
    [AprtmentAdComplaint.OBSCENE_CONTENT]: 'Непристойный контент',
    [AprtmentAdComplaint.OTHER]: 'Другое',
  };

  return (
    <Show>
      <TabbedShowLayout>
        <Tab label="Жалоба">
          <SimpleShowLayout>
            <TextField label="ID жалобы" source="id" />
            <TextField label="ID отправителя" source="userId" />
            <Button onClick={() => redirectToUser(data.userId)} variant="contained">
              Профиль отправителя
            </Button>
            <TextField label="ID объявления" source="apartmentAdId" />
            <Button onClick={() => redirectToApartmentAd(data.apartmentAdId)} variant="contained">
              Профиль объявления
            </Button>
            <Typography component="p" variant="body1">
              Причины:
            </Typography>
            {data.type.map((complaint: AprtmentAdComplaint) => {
              console.log(complaint);
              return (
                <Typography component="span" variant="body1" key={complaint}>
                  {renderComplaint[complaint]}
                </Typography>
              );
            })}
            {data.reason && <TextField label="Комментарий" source="reason" />}
            <BooleanField label="Жалоба рассмотрена" source="isViewed" />
            <DateField locales="rus" showTime label="Создан" source="createdAt" />
            <DateField locales="rus" showTime label="Обновлен" source="updatedAt" />
            <Button onClick={handleSubmit} disabled={data.isViewed} variant="contained">
              Жалоба рассмотрена
            </Button>
          </SimpleShowLayout>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default ApartmentAdComplaintShow;
