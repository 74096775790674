import { LongTermRentEdit, ShortTermRentEdit } from 'components';
import { TermRentStatus } from 'const';
import { useEffect, useState } from 'react';
import { Edit, SaveButton, SimpleForm, TextInput, Toolbar, useRedirect } from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { http } from 'services';
import { ResourceName } from 'types';

const MyToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

const ApartmentAdEdit = () => {
  const { id } = useParams();
  const redirect = useRedirect();
  const [metaTags, setMetaTags] = useState<{ keywordsSeo: string; titleSeo: string; descriptionSeo: string }>();
  const [loading, setLoading] = useState(true);

  const handleSubmit = async (data: FieldValues) => {
    await changeDescription(data.description.name, data.description.description);
    await changeMetatags(data.seo.h1, data.seo.title, data.seo.description);
    await changeAllTermRentStatus(data);

    redirect(`/${ResourceName.APARTMENT_AD}`);
  };

  useEffect(() => {
    http.get(`${ResourceName.APARTMENT_AD}/${id}/get-apartment-ad-metatags`).then((res) => {
      if (res.data.props) setMetaTags(res.data.props);
      setLoading(false);
    });
  }, [id]);

  const changeDescription = async (name: string, descriptionText: string) => {
    await http.patch(`${ResourceName.APARTMENT_AD}/${id}/edit-apartment-ad-description`, {
      name,
      descriptionText,
    });
  };

  const changeMetatags = async (h1: string, title: string, description: string) => {
    await http.patch(`${ResourceName.APARTMENT_AD}/${id}/edit-apartment-ad-metatags`, {
      h1,
      title,
      description,
    });
  };

  const changeAllTermRentStatus = async (data: FieldValues) => {
    const shortTermRentStatus = data.shortTermRent?.status?.[0];
    const longTermRentStatus = data.longTermRent?.status?.[0];

    await changeTermRentStatus(shortTermRentStatus, ResourceName.SHORT_TERM_RENTS);
    await changeTermRentStatus(longTermRentStatus, ResourceName.LONG_TERM_RENTS);
  };

  const changeTermRentStatus = async (status: string, resourceName: ResourceName) => {
    const isStatusPaused = status === TermRentStatus.PAUSED;
    const isStatusCorrect = checkIsTermRentStatusCorrect(status);

    if (isStatusCorrect) {
      await toggleTermRentStatus(isStatusPaused, resourceName);
    }
  };

  const checkIsTermRentStatusCorrect = (status: string) => {
    const isStatusPaused = status === TermRentStatus.PAUSED;
    const isStatusPublished = status === TermRentStatus.PUBLISHED;

    const isStatusCorrect = isStatusPaused || isStatusPublished;

    return isStatusCorrect;
  };

  const toggleTermRentStatus = async (isStatusPaused = false, resourceName: ResourceName) => {
    if (isStatusPaused) {
      await http.patch(`${resourceName}/${id}/pause`);
    } else {
      await http.patch(`${resourceName}/${id}/publish`);
    }
  };

  return loading ? (
    <></>
  ) : (
    <Edit>
      <SimpleForm onSubmit={handleSubmit} toolbar={<MyToolbar />}>
        <TextInput label="Название" source="description.name" defaultValue="" multiline sx={{ width: '600px' }} />
        <TextInput
          label="Описание"
          source="description.description"
          defaultValue=""
          multiline
          sx={{ width: '600px' }}
        />
        <TextInput
          label="Мета-тег h1"
          source="seo.h1"
          defaultValue={metaTags?.keywordsSeo || ''}
          multiline
          sx={{ width: '600px' }}
        />
        <TextInput
          label="Мета-тег title"
          source="seo.title"
          defaultValue={metaTags?.titleSeo || ''}
          multiline
          sx={{ width: '600px' }}
        />
        <TextInput
          label="Мета-тег description"
          source="seo.description"
          defaultValue={metaTags?.descriptionSeo || ''}
          multiline
          sx={{ width: '600px' }}
        />
        <LongTermRentEdit />
        <ShortTermRentEdit />
      </SimpleForm>
    </Edit>
  );
};

export default ApartmentAdEdit;
