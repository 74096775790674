import Button from '@mui/material/Button';
import { LoadingButton } from 'components/LoadingButton';
import { useEffect, useMemo, useState } from 'react';
import { BooleanField, DateField, Show, SimpleShowLayout, TextField, useGetOne, useRedirect } from 'react-admin';
import { useParams } from 'react-router-dom';
import dayjs from 'services/dayjs';

import { StyledTextField } from '../../../components';
import { CancelRentLandlordModal } from '../../../components/CancelRentLandlordModal';
import CancelRentTenantModal from '../../../components/CancelRentTenantModal/CancelRentTenantModal';
import { ResourceName } from '../../../types';

const ContractShow = () => {
  const { id } = useParams();

  const { data: contractData, refetch } = useGetOne(ResourceName.CONTRACTS, { id: id });

  const [isPending, setIsPending] = useState(false);

  useEffect(() => {
    if (contractData?.isPending != null) {
      setIsPending(contractData?.isPending);
    }
  }, [contractData?.isPending]);

  const handleClick = (data: { isPending: boolean }) => {
    setIsPending(data.isPending);
    refetch();
  };

  const isDeadlineForCancelingTenant = useMemo(
    () => dayjs().utc().diff(dayjs(contractData?.arrivalDate), 'hours', true) >= 24,
    [contractData],
  );

  const isContractCanceled = useMemo(
    () => contractData?.status === 'REJECTED' || contractData?.status === 'COMPLETED',
    [contractData],
  );

  const [isOpenTenantModal, setIsOpenTenantModal] = useState(false);
  const handleOpenTenantModal = () => setIsOpenTenantModal(true);
  const handleCloseTenantModal = () => setIsOpenTenantModal(false);

  const [isOpenLandlordModal, setisOpenLandlordModal] = useState(false);
  const handleOpenLandlordModal = () => setisOpenLandlordModal(true);
  const handleCloseLandlordModal = () => setisOpenLandlordModal(false);

  const redirect = useRedirect();

  const redirectToUser = async (id: string) => {
    redirect(`/${ResourceName.USERS}/${id}/show`);
  };

  const redirectToApartmentAd = async (id: string) => {
    redirect(`/${ResourceName.APARTMENT_AD}/${id}/show`);
  };

  return (
    <Show>
      <SimpleShowLayout>
        <LoadingButton
          variant="contained"
          onClick={handleOpenTenantModal}
          disabled={isDeadlineForCancelingTenant || isContractCanceled || isPending}
          loading={isPending}>
          Отменить аренду по заявке арендатора
        </LoadingButton>
        <LoadingButton
          variant="contained"
          onClick={handleOpenLandlordModal}
          disabled={isContractCanceled || isPending}
          loading={isPending}>
          Отменить аренду по заявке арендодателя
        </LoadingButton>
        <Button onClick={() => redirectToUser(contractData?.landlordId)} variant="contained">
          Профиль арендодатель
        </Button>
        <StyledTextField label="ID арендатора" text={contractData?.tenantId} />
        <Button onClick={() => redirectToUser(contractData?.tenantId)} variant="contained">
          Профиль арендатор
        </Button>
        <StyledTextField label="ID объявления" text={contractData?.apartmentAdId} />
        <Button onClick={() => redirectToApartmentAd(contractData?.apartmentAdId)} variant="contained">
          Объявление
        </Button>
        <TextField label="ID" source="id" sortable={false} />
        <TextField label="Стоимость" source="cost" />
        <DateField showTime label="Дата создания" source="createdAt" />
        <DateField showTime label="Дата прибытия" source="arrivalDate" />
        <DateField showTime label="Дата отъезда" source="departureDate" />
        <TextField label="Город" source="baseApartmentAdData.address.city" />
        <TextField label="Улица" source="baseApartmentAdData.address.street" />
        <TextField label="Номер дома" source="baseApartmentAdData.address.houseNumber" />
        <TextField label="Статус" source="status" />
        <BooleanField label="С животными" source="rules.allowedWithPets" />
        <BooleanField label="С детьми" source="rules.allowedWithChildren" />
        <BooleanField label="Курение" source="rules.allowedToSmoke" />
        <BooleanField label="Вечеринки и мероприятия" source="rules.allowedToHangingOut" />
        <CancelRentTenantModal
          id={contractData?.id}
          isOpen={isOpenTenantModal}
          onSubmit={handleClick}
          onClose={handleCloseTenantModal}
        />
        <CancelRentLandlordModal
          id={contractData?.id}
          isOpen={isOpenLandlordModal}
          onSubmit={handleClick}
          onClose={handleCloseLandlordModal}
        />
      </SimpleShowLayout>
    </Show>
  );
};

export default ContractShow;
