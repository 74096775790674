import { ListProps } from '@material-ui/core';
import { StyledImageField } from 'components';
import { FC, useEffect, useState } from 'react';
import { AutocompleteInput, Datagrid, EditButton, List, TextField } from 'react-admin';
import { http } from 'services';
import { ResourceName } from 'types';

const ApartmentFacilitiesSubList: FC<ListProps> = (props) => {
  const [choices, setChoices] = useState([]);
  useEffect(() => {
    http.get(ResourceName.APARTMENT_FACILITIES).then((response) => setChoices(response.data.data));
  }, []);
  const CustomersFilter = [
    <AutocompleteInput key="id" source="categoryId" optionText="title" choices={choices} alwaysOn />,
  ];
  return (
    <List filters={CustomersFilter} {...props}>
      <Datagrid rowClick="show" isRowSelectable={() => false} bulkActionButtons={false}>
        <StyledImageField label="Аватар" source="url.fileKey" sortable={false} />
        <TextField label="ID удобства" source="id" sortable={false} />
        <TextField label="Категория" source="category.title" sortable={false} />
        <TextField label="Название" source="title" sortable={false} />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default ApartmentFacilitiesSubList;
