import { TermRentStatus } from 'const';
import { SelectInput, SimpleShowLayout, useRecordContext } from 'react-admin';
import styled from 'styled-components';

const ShortTermRentEdit = () => {
  const record = useRecordContext();

  const isShortTermRent = !!record?.shortTermRent;
  const isShortTermStatusPaused = record.shortTermRent?.status?.[0] === TermRentStatus.PAUSED;
  const isShortTermStatusPublished = record.shortTermRent?.status?.[0] === TermRentStatus.PUBLISHED;
  const isShortTermStatus = isShortTermStatusPaused || isShortTermStatusPublished;

  if (!isShortTermRent || !isShortTermStatus) {
    return null;
  }

  return (
    <StyledSimpleShowLayout>
      <Title>Краткосрочная аренда</Title>
      <SelectInput
        label="Статус"
        source="shortTermRent.status.[0]"
        required
        choices={[
          { id: TermRentStatus.PUBLISHED, name: TermRentStatus.PUBLISHED },
          { id: TermRentStatus.PAUSED, name: TermRentStatus.PAUSED },
        ]}
      />
    </StyledSimpleShowLayout>
  );
};

export default ShortTermRentEdit;

const StyledSimpleShowLayout = styled(SimpleShowLayout)`
  padding: 0;
`;

const Title = styled.h4`
  margin: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  color: rgba(0, 0, 0, 0.6);
`;
