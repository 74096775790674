import Typography from '@mui/material/Typography';
import { StyledImageField } from 'components';
import DeleteButton from 'components/DeleteButton';
import { useState } from 'react';
import {
  Confirm,
  DateField,
  EmailField,
  Show,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TextField,
  useDelete,
  useNotify,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { ResourceName } from 'types';

const UsersShow = () => {
  // const record = useRecordContext();
  const notify = useNotify();
  const { id } = useParams();
  const [open, setOpen] = useState(false);

  const [deleteOne, { isLoading }] = useDelete(
    ResourceName.USERS,
    { id },
    {
      onError: () => {
        notify('Пользователя нельзя удалить ', { type: 'error' });
      },
      onSuccess: () => {
        notify('Пользователь удален', { type: 'success' });
      },
    },
  );

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = async () => {
    await deleteOne();
    setOpen(false);
  };

  return (
    <Show>
      <TabbedShowLayout>
        <Tab label="О пользователе">
          <SimpleShowLayout>
            <TextField label="ID" source="id" />
            <StyledImageField $size={200} label="Аватар" source="avatarKey" />
            <TextField label="Телефон" source="phone" />
            <EmailField label="Почта" source="email" />
            <TextField label="Имя" source="firstName" />
            <TextField label="Фамилия" source="lastName" />
            <TextField label="Дата рождения" source="birthDate" />
            <TextField label="Пол" source="gender" />
            <TextField label="Статус" source="identityStatus" />
            <DateField locales="rus" showTime label="Создан" source="createdAt" />
            <DateField locales="rus" showTime label="Обновлен" source="updatedAt" />
            <Typography component="p" variant="body1">
              Контактное лицо при ЧП
            </Typography>
            <TextField label="Имя" source="guarantors[0].firstName" />
            <TextField label="Фамилия" source="guarantors[0].lastName" />
            <TextField label="Телефон" source="guarantors[0].phone" />
          </SimpleShowLayout>
          <DeleteButton text="Удалить пользователя" onClick={handleClick} />
          <Confirm
            isOpen={open}
            loading={isLoading}
            title="Удаление пользователя"
            content="Вы уверены что хотите удалить пользователя?"
            onConfirm={handleConfirm}
            onClose={handleDialogClose}
          />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default UsersShow;
