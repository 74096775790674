import { ListProps } from '@material-ui/core';
import { FC } from 'react';
import { Datagrid, DeleteButton, EditButton, List, TextField } from 'react-admin';

const ShortsHeadingList: FC<ListProps> = (props) => {
  return (
    <List {...props}>
      <Datagrid rowClick="show" isRowSelectable={() => false} bulkActionButtons={false}>
        <TextField label="ID" source="id" sortable={false} />
        <TextField label="Очередь" source="order" sortable={false} />
        <TextField label="Название" source="tag" sortable={false} />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

export default ShortsHeadingList;
