import { useEffect, useState } from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  Create,
  ImageField,
  ImageInput,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useRedirect,
} from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Aws, http } from 'services';
import { ResourceName } from 'types';

const MyToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);
interface IForm {
  id: string;
  title: string;
  description: string;
  IsPopular: boolean;
  categoryId: string;
  url: {
    fileKey: string;
  }
}
const ApartmentFacilitiesSubEdit = () => {
  const { id } = useParams();
  const redirect = useRedirect();
  const [form, setForm] = useState<IForm | null>(null);
  const [choices, setChoices] = useState([]);

  const handleSubmit = async (data: FieldValues) => {
    const signedUrl = await Aws.getSignedUrl({ fileName: data.icons.title, fileCategory: 'ICON' });
    await Aws.loadToBucket(signedUrl, data.icons.rawFile);

    await http.put(ResourceName.APARTMENT_SUB_FACILITIES + `/${id}`, {
      title: data.title,
      description: data.description,
      IsPopular: data.IsPopular,
      url: signedUrl
    });
    redirect('/' + ResourceName.APARTMENT_SUB_FACILITIES);
  };

  useEffect(() => {
    http.get(`${ResourceName.APARTMENT_SUB_FACILITIES}/${id}`).then((res) => {
      setForm(res.data);
    });
  }, [id]);

  useEffect(() => {
    http.get(ResourceName.APARTMENT_FACILITIES).then((response) => setChoices(response.data.data));
  }, []);
  if (!form) {
    return <></>;
  }
  return (
    <Create>
      <SimpleForm onSubmit={handleSubmit} toolbar={<MyToolbar />}>
        <AutocompleteInput
          source="categoryId"
          optionText="title"
          choices={choices}
          defaultValue={form?.categoryId || ''}
          sx={{ width: '600px' }}
        />
        <TextInput label="Название" source="title" defaultValue={form?.title || ''} sx={{ width: '600px' }} />
        <TextInput
          label="Описание"
          source="description"
          defaultValue={form?.description || ''}
          sx={{ width: '600px' }}
        />
        <BooleanInput label="Популярный" source="IsPopular" defaultValue={form?.IsPopular} />
        <ImageInput source="icons" label="Иконка" accept="image/*">
          <ImageField source="src" title="title" src={form?.url?.fileKey} />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};

export default ApartmentFacilitiesSubEdit;
