import { AUTH_CHECK, AUTH_ERROR, AUTH_LOGIN, AUTH_LOGOUT, AuthActionType } from 'react-admin';
import { http, StorageService } from 'services';

const authProvider = (type: AuthActionType, params: any): Promise<void> => {
  if (type === AUTH_LOGIN) {
    const { username: login, password } = params;

    return http
      .post('sign-in', { login, password })
      .then(({ data: { token } }) => {
        http.setAuthorizationHeader(token);
        StorageService.setAccessToken(token);
      })
      .catch((error) => {
        throw new Error(error.message);
      });
  }

  if (type === AUTH_LOGOUT) {
    StorageService.resetAccessToken();
    return Promise.resolve();
  }

  if (type === AUTH_ERROR) {
    const {
      response: { status },
    } = params;
    if (status === 401 || status === 403) {
      StorageService.resetAccessToken();
      return Promise.reject();
    }
    return Promise.resolve();
  }

  if (type === AUTH_CHECK) {
    const token = StorageService.getAccessToken();
    if (token) {
      http.setAuthorizationHeader(token);
      return Promise.resolve();
    }
    return Promise.reject();
  }
  return Promise.reject(new Error('Unknown method'));
};

export default authProvider;
