import { ConfirmOptions, ConfirmTypes } from 'types/confirm';

export const confirmOptions: ConfirmOptions[] = [
  {
    id: ConfirmTypes.APPROVED,
    name: 'Подтвержден',
  },
  {
    id: ConfirmTypes.REJECTED,
    name: 'Отклонен',
  },
];
