import { ImageField } from 'react-admin';
import styled, { css } from 'styled-components';

type StyledImageFieldProps = { $size?: number; $margin?: number };

export default styled(ImageField)<StyledImageFieldProps>`
  ${({ $size = 50, $margin = 0 }) => css`
    max-width: ${$size}px;
    width: fit-content !important;
    height: ${$size}px;
    margin-right: ${$margin}px !important;
    margin-top: ${$margin}px !important;

    img {
      max-width:: ${$size}px !important;
      width: fit-content !important;
      height: ${$size}px !important;
      margin: 0 !important;
    }
  `}
`;
