import { ListProps } from '@material-ui/core';
import { FC } from 'react';
import { Datagrid, DateField, List, TextField, TextInput } from 'react-admin';
import styled from 'styled-components';

const CustomersFilter = [<TextInput key="id" label="Поиск по id" source="id" alwaysOn />];

const ApartmentAdRequestShortRentList: FC<ListProps> = (props) => {
  return (
    <List filters={CustomersFilter} sort={{ field: 'createdAt', order: 'DESC' }} {...props}>
      <Datagrid rowClick="show" isRowSelectable={() => false} bulkActionButtons={false}>
        <StyledTextField label="Название" source="description.name" sortable={false} />
        <StyledTextField label="Описание" source="description.description" sortable={false} />
        <TextField label="Тип жилья" source="apartmentType" />
        <TextField label="Страна" source="country" />
        <TextField label="Город" source="city" />
        <DateField locales="rus" showTime label="Создан" source="createdAt" />
        <DateField locales="rus" showTime label="Обновлен" source="updatedAt" />
      </Datagrid>
    </List>
  );
};

export default ApartmentAdRequestShortRentList;

const StyledTextField = styled(TextField)`
  display: block;
  max-width: 400px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
