import { useEffect, useState } from 'react';
import { Create, SaveButton, SimpleForm, TextInput, Toolbar, useRedirect } from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { http } from 'services';
import { ResourceName } from 'types';

const MyToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);
interface IForm {
  id: string;
  title: string;
}
const ApartmentFacilitiesEdit = () => {
  const { id } = useParams();
  const redirect = useRedirect();
  const [form, setForm] = useState<IForm | null>(null);

  const handleSubmit = async (data: FieldValues) => {
    await http.put(ResourceName.APARTMENT_FACILITIES + `/${id}`, {
      title: data.title,
    });
    redirect('/' + ResourceName.APARTMENT_FACILITIES);
  };

  useEffect(() => {
    http.get(`${ResourceName.APARTMENT_FACILITIES}/${id}`).then((res) => {
      setForm(res.data);
    });
  }, [id]);
  if (!form) {
    return <></>;
  }
  return (
    <Create>
      <SimpleForm onSubmit={handleSubmit} toolbar={<MyToolbar />}>
        <TextInput label="Название" source="title" defaultValue={form?.title || ''} sx={{ width: '600px' }} />
      </SimpleForm>
    </Create>
  );
};

export default ApartmentFacilitiesEdit;
